.requestViewRow {
    display: flex;
    justify-content: space-around;
    padding: 1vh 2vw;
}

.requestViewRowTwo {
    display: flex;
    padding: 1vh 0vw 1vh 0vw;
}

.exampleImage {
    max-width: 27vw;
    max-height: 36vw;
    border: rgb(237, 181, 108) solid 3px;
    border-radius: 20px 0px 0px 20px;
}

.textAndIcon {
    background-color: rgb(237, 181, 108);
    border-radius: 0px 20px 20px 0px;
}

@media (max-width: 600px) {
    .examplePicIcon {
        color: darkgreen;
        font-size: 6.5vw;
    }
    
    .badPicIcon {
        color: crimson;
        font-size: 6.5vw;
    }

    .examplePicText {
        font-size: 2.3vw;
        text-align: left;
        max-width: 14vw;
        margin: 0px 5px;
        white-space: pre-wrap;
    }
}

@media (min-width: 601px) {
    .examplePicIcon {
        color: darkgreen;
        font-size: 40px;
    }
    
    .badPicIcon {
        color: crimson;
        font-size: 40px;
    }

    .examplePicText {
        font-size: 13px;
        text-align: left;
        max-width: 17vw;
        margin: 0px 9px;
        white-space: pre-wrap;
    }
}


