.trackerContainer {

}

.trackerSubContainer {
    display: flex!important;
    justify-content: center;
    padding: 12px 4vw;
}

.trackerPage {
    border: 1px solid;
    border-left: 0px;
    border-color: rgb(150, 113, 135);
    width: 16vw;
    text-align: center;
    font-size: 2vh;
    border-radius: 5px;
}

.backButton {
    border: 1px solid;
    border-color: rgb(150, 113, 135);
    width: 5vw;
    text-align: center;
    font-size: 2vh;
    border-radius: 5px;
    background-color: white;
    -webkit-text-stroke: 1px;
    margin-right: 4vw;
}

.backButtonDisabled {
    border: 1px solid;
    border-color: rgb(214, 197, 207);
    color: rgb(214, 197, 207);
    width: 5vw;
    text-align: center;
    font-size: 2vh;
    border-radius: 5px;
    background-color: white;
    -webkit-text-stroke: 1px;
    margin-right: 4vw;
}

.trackerPageFirst {
    border: 1px solid;
    border-color: rgb(150, 113, 135);
    width: 16vw;
    text-align: center;
    font-size: 2vh;
    border-radius: 5px;
}