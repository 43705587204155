.viewContainer {
    align-items: left;
    justify-items: left;
    text-align: left;
    background-color: #ffbe6f;
    background-image: url("https://kaylicolorstorageprod.blob.core.windows.net/public-images/homeBackgroundTwo.png");
    background-size:cover;
    padding-bottom: 0px;
    font-family: Averta,sans-serif,sans-serif;
}

.centerIt {
    justify-items: center;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    text-justify: center;
}

.rightIt {
    justify-items: right;
    text-align: right;
}

.statusMessage {
    padding: 10px 5px;
    font-weight: bold;
}

.commentForm {
    padding: 0px 0px 0px 30px;
}

.selfieTitle {
    margin: 8px 0px;
}

.maxPhotoLimit {
    
}

.deleteAllPhotosButton {
    margin-bottom: 12px;
}

.basicInfoButton {
}

.basicInfo {
    align-items: right;
    text-align: right;
    justify-content: right;
    justify-items: right;
    text-justify: right;
    align-content: right;
}

@media (max-width: 500px) {
    .selfieExplanation {
        font-size: 3.7vw;
        padding: 3px 12px;
    }

    .commentContainer {
        font-size: 3.2vw;
        padding: 10px 10px 5px 10px;
    }
}

@media (min-width: 501px) {
    .selfieExplanation {
        font-size: 16px;
        padding: 3px 12px;
    }

    .commentContainer {
        font-size: 14px;
        padding: 10px 10px 5px 10px;
    }
}

input[type='file'] {
    color: transparent;
  }

.uploadImageRow {
    display: flex;
    justify-content: space-evenly;
    margin: 5px 0px 20px 0px;
}

.buttonsContainer {
}

.anythingElse {
    width: 90vw;
    min-height: 150px;
    margin: 5px 5px 0px 5px;
    border-radius: 5px;
    border-width: 1px;
}

.anythingElseContainer {
    margin: 5px;
}

.contextButton {
    width: min-content;
    margin: 0px 0px 15px 20px;
}

.charCount {
    font-size: 8px;
    text-align: right;
    margin-right: 5vw;
}

.alreadyInQueueMessage {
    font-weight: bold;
    margin: 0px 5px
}

.uploadImageContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: xx-small;
}

.requestViewInput {
    margin: 5px 20px 5px 0px;
    border-radius: 5px;
    border-width: 1px;
    max-width: 130px;
}

.requestViewButton {
    border-radius: 10px;
    border-width: 1px;
    background-color: gainsboro;
}

.requestViewButton:focus {
    background-color: #bee492;
}

.greenButton:focus {
    background-color: #bee492;
}

.requestViewInput:focus {
    outline:none;
}

.removeButton {
    margin-top: 5px;
}

.legacyPhotosContainer {
    margin: 10px 20px;
}

.legacyPhotosSubContainer {
    display: flex;
    flex-wrap: wrap;
}

.mainFileSelect {
    display: none;
}

.contextPhotoButton {
    margin-top: 15px;
    margin-bottom: 15px;
}