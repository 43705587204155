.homeHeader {
    text-align: center;
    font-size: xx-large;
    padding: 40px 10px 10px 10px;
    font-family: sans-serif;
}

.homeContainer {
    align-items: center;
    justify-items: center;
    text-align: center;
    background-color: #ffbe6f;
    background-image: url("https://kaylicolorstorageprod.blob.core.windows.net/public-images/homeBackgroundTwo.png");
    background-size:cover;
    padding-bottom: 40px;
    font-family: Averta,sans-serif,sans-serif;
}

.errorMessage {
    font-size: xx-large;
    color: crimson;
}

.otherPageHeader {
    text-align: center;
    font-size: xx-large;
    padding: 50px 10px 10px 10px;
    font-family: sans-serif;
}

.homeBody {
    margin: 20px 20px;
}

.existingList {
    margin-bottom: 0px;
    padding-bottom: 20px;
}

.sendEmail {
    border-radius: 5px 5px 5px 5px;
    border-width: 1px;
    border-color: rgb(16, 48, 20);
    background-color: #bee492;
    border-style:solid;
    margin: 8px 0px 30px 0px;
}

.sendEmail:focus {
    background-color: green;
}

.sendEmail:disabled {
    background-color: darkgray;
    border-color: rgb(110, 110, 110);

}

.enterEmail {
    border-radius: 5px 5px 5px 5px;
    border-width: 1px;
    border-color: gainsboro;
    border-style:solid;
    min-width: 70vw;
}

.enterEmail:focus {
    outline:none;
}

.existingTicket {
    text-decoration: underline;
    color: cadetblue;
    padding: 2px 0px;
}

.subHeading {
    margin: 4px;
}

.enterCode {
    max-width: 30vw;
    text-align: center;
}

.faqContainer {
    padding: 9px;
}

.loadingBody {
    background-color: #ffbe6f;
    min-height: 65vh;
}