.svgRight {
    width: 100%;
    border-radius: 0px 20px 20px 0px;
}

.svgRight {
    width: 100%;
}

.svgLeft {
    width: 100%;
    border-radius: 20px 0px 0px 20px;
}

.svgBoth {
    width: 95%;
    border-radius: 20px 20px 20px 20px;
}

.template {
    width: 100%;
    height: 100%;
}

.container {
    position: relative;
    padding: 0px!important;
    border-radius: 50px;
}

.label {
    position: absolute;
    top: 1.5%;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: max-content; /* Need a specific value to work */
}

@media (max-width: 500px) {
    .smallLabel {
        font-size: 2.5vw;
        position: absolute;
        top: 1.5%;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
        width: max-content; /* Need a specific value to work */
        text-shadow:
    1px 1px 0 #fff,
    -1px 1px 0 #fff,
    -1px -1px 0 #fff,
    1px -1px 0 #fff;
    }
}

@media (min-width: 501px) {
    .smallLabel {
        font-size: medium;
        position: absolute;
        top: 1.5%;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
        width: max-content; /* Need a specific value to work */
        text-shadow:
    1px 1px 0 #fff,
    -1px 1px 0 #fff,
    -1px -1px 0 #fff,
    1px -1px 0 #fff;
    }
}

.bigLabel {
    font-size: large;
    position: absolute;
    top: 1.5%;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: max-content; /* Need a specific value to work */
}