@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-image-editor/styles/material.css";

* {box-sizing: border-box;}

.cropPreview {
  display: flex;
  align-items: center;
  justify-items: center;
  max-height: 300px;
  max-width: 150px;
}

.comparisonContainer {
  display: flex!important;
  justify-content: center;
  max-width: 400vh;
  margin: 8px;
  padding: 0px;
}

.comparisonHalf {
  height: 100%
}

.comparisonFrame {
  max-width: 300px;
}

.textarea {
  width: 100%;
  min-height: 100px;
}

.compareDropDown {
  max-width: 140px;
}

.blurbThumbnail {
  border: 2px solid;
  border-radius: 5px;
  width: min-content;
  padding: 5px;
  margin: 5px;
  white-space: nowrap;
}

.basicInfoAdmin {
  font-size: medium;
}

.needsMoreInfo {
  font-size: small;
  background-color: brown;
  border-color: burlywood;
}

.croppingButton {
  margin: 5px;
}

.inspoPic {
  margin: 3px 3px 0px 3px;
}

.inspoContainer {
  display: flex;
  flex-wrap: wrap;
}

.inspoUnit {
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
}

.inspoRemoveButton {
  font-size: xx-small;
  margin-top: 0px;
  width: min-content;
}

.kayCoDropDown {
  width: min-content;
}

.frameTilesContainer {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
}

.frameTile {
  margin: 2px;
}

.contextPhotoTile {
  margin: 2px;
  border: 2px solid;
  border-radius: 4px;
}

.missingBlurb {
  color: brown;
  font-weight: bold;
}