.verifyContainer {
    align-items: center;
    justify-items: center;
    text-align: center;
    background-color: #ffbe6f;
    background-image: url("https://kaylicolorstorageprod.blob.core.windows.net/public-images/homeBackgroundTwo.png");
    background-size:cover;
    padding-bottom: 40px;
    font-family: Averta,sans-serif,sans-serif;
}

.resendButton { 
    border-radius: 5px 5px 5px 5px;
    border-width: 1px;
    border-color: rgb(116, 23, 23);
    color: rgb(116, 23, 23);
    background-color: rgb(255, 217, 217);
    height: 100%;
    border-style:solid;
    margin: 0px 10px;
}

.yesIGotEmail {
    border-radius: 5px 5px 5px 5px;
    border-width: 1px;
    height: 100%;
    border-style:solid;
    margin: 0px 10px;
    border-color: green;
    background-color: rgb(202, 245, 202);
    color: rgb(15, 58, 15);
}

.verifyBody {
    margin: 15px 20px;
    text-align: left;
}

.emailInfo {
    font-size: small;
}

.didYouGetEmail {
    margin-bottom: 20px;
    font-size: large;
}