.paymentContainer {
    background-color: #ffbe6f;
    background-image: url("https://kaylicolorstorageprod.blob.core.windows.net/public-images/homeBackground.png");
    background-size:cover;
}

.paymentContainerTwo {
    background-color: azure;
    padding-bottom: 15px;
    padding-left: 12px;
    padding-right: 12px;
}

.paymentHeader {
    text-align: center;
    font-size: x-large;
    padding: 20px 20px 0px 20px;
    font-family: sans-serif;
}

.paymentButton {
    margin: 10px 10px 20px 10px;
}

.troublePaying {
    font-size: large;
    margin-top: 30px;
    font-weight: bold;
}